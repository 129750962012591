import React, { FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form";
import { CreateNewSignUp } from "../../services/EpsilonService";
import LoaderIcon from "../../images/icons/loader";

import Button from "../Button";
import "./styles.scss";

import { Field } from "../Forms";
import { SurveyQuestions } from "../../types/SanityTypes";
import { useState } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event58 } from "../../analytics/event58";
import { event31 } from "../../analytics/event31";

type JoinSheaFormProps = {
  questions: SurveyQuestions[];
  getQuestionById: any;
};

const JoinSheaForm: FunctionComponent<JoinSheaFormProps> = ({ questions, getQuestionById }) => {
  const { language, sanityLabels } = useContext(LocalizedContext);
  const errorMessages = sanityLabels?.errorMessages;
  const { isoCountry, epsilonSignUpCCID, epsilonCorporateCCID } = useSiteMetadata(language);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleDateOfBirth = (dateOfBirth: any) => {
    const dateOfBirthDate = new Date(dateOfBirth);
    return new Date() >= new Date(dateOfBirthDate.setFullYear(dateOfBirthDate.getFullYear() + 13));
  };

  const formFields: any = [
    [
      {
        type: "text",
        name: "firstName",
        label: "First Name",
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "First name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 40,
            message: "First name should not exceed more than 40 characters"
          }
        },
        message: errorMessages?.validFirstName || "Please, enter First name"
      },
      {
        type: "text",
        name: "lastName",
        label: "Last Name",
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "Last name should not contain any numbers, special symbols"
          },
          maxLength: {
            value: 40,
            message: "Last name should not exceed more than 40 characters"
          }
        },
        message: errorMessages?.validLastName || "Please, enter Last name"
      }
    ],
    [
      {
        type: "date",
        name: "dateOfBirth",
        label: "Date Of Birth",
        placeholder: "01.01.0001",
        rules: {
          required: true,
          validate: (value: any) => {
            if (!handleDateOfBirth(value)) {
              return "Please, enter a correct date. Age should not be less than 13.";
            }
          }
        },
        message: "Please, enter date of birth"
      },
      {
        type: "email",
        name: "email",
        label: "E-mail Address",
        rules: {
          required: true,
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Email entered is Invalid"
          },
          maxLength: {
            value: 70,
            message: "Email should not exceed more than 70 characters"
          }
        },
        message: errorMessages?.validEmail || "Please, enter email"
      }
    ],
    [
      {
        type: "text",
        name: "address1",
        formLabel: "Current Address",
        label: "Address 1",
        layout: {
          size: 6
        },
        rules: {
          required: true,
          maxLength: {
            value: 50,
            message: "Address should not exceed more than 50 characters"
          }
        },
        message: errorMessages?.validAddress1 || "Please, enter an address"
      },
      {
        type: "text",
        name: "city",
        label: "City",
        rules: {
          required: true,
          maxLength: {
            value: 40,
            messaage: "City should not exceed more than 40 characters"
          }
        },
        message: errorMessages?.validCity || "Please, enter a city"
      },
      {
        type: "text",
        name: "state",
        label: "State",
        rules: {
          required: true,
          pattern: {
            value: /^[A-Za-z]+$/i,
            message: "State should not contain any numbers or special symbols"
          },
          maxLength: {
            value: 2,
            message: "State takes only 2 characters"
          }
        },
        message: errorMessages?.validState || "Please, enter a valid state code (2 letters)"
      },
      {
        type: "text",
        name: "postalCode",
        label: "Zip Code",
        rules: {
          required: true,
          maxLength: {
            value: 10,
            message: "Postal code should not exceed more than 10 characters"
          },
          pattern: {
            value: /^[0-9]{5}(?:-[0-9]{4})?$/,
            message: "Zip code entered is invalid"
          }
        },
        message: errorMessages?.validZipCode || "Please, enter a ZIP code"
      }
    ],
    [...questions]
  ];

  const transformSurveyData = surveyData => {
    const surveyResponse: any = [];
    Object.entries(surveyData)
      .filter(surveyQuestion => surveyQuestion && surveyQuestion[1])
      .forEach(surveyQuestion => {
        const [id, answer] = surveyQuestion;
        const question = getQuestionById(id.split("cq-")[1]);
        if (question && question.questionType === "radio") {
          const choice = question.choices.find(choice => choice.surveyAnswerId === answer);
          surveyResponse.push({
            questionId: choice?.surveyQuestionAnswerId,
            answerId: choice?.surveyAnswerId,
            answer: choice?.choice
          });
        } else {
          surveyResponse.push({
            answerId: question?.surveyAnswerId,
            questionId: question?.surveyQuestionAnswerId,
            answer
          });
        }
      });
    return surveyResponse;
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleContactSubmit = async (data: any) => {
    const {
      firstName,
      lastName,
      email,
      dateOfBirth,
      countryCode = "US",
      city,
      state,
      postalCode,
      address1,
      ...surveyQuestions
    } = data;

    try {
      setIsLoading(true);
      setError(null);

      await CreateNewSignUp({
        signUpData: {
          firstName,
          lastName,
          dateOfBirth,
          email,
          countryCode: isoCountry,
          address: {
            city,
            state,
            postalCode,
            addressLine1: address1
          }
        },
        surveyData: transformSurveyData(surveyQuestions),
        campaigns: [epsilonSignUpCCID, epsilonCorporateCCID]
      });
      setIsFormSubmitted(true);
      event58();
      event31("Join Shea Form");
    } catch (err) {
      console.log("Error", err);
      if (err?.response?.data) setError(err?.response?.data);
    } finally {
      setIsLoading(false);
      window.signupStarted = false;
    }
  };

  return (
    <section className="c-join-shea__form" data-testid="join-shea-form">
      <section className="c-join-shea__section">
        {!isFormSubmitted ? (
          <form onSubmit={handleSubmit(handleContactSubmit)} className="container">
            {formFields.map((field, i) =>
              Array.isArray(field) ? (
                <div className="row" key={i}>
                  {field.map(field => (
                    <Field
                      key={field.name}
                      {...field}
                      registerInput={register}
                      errors={errors}
                      data-analytics-event57
                    />
                  ))}
                </div>
              ) : (
                <Field key={field.name} {...field} registerInput={register} errors={errors} data-analytics-event57 />
              )
            )}
            <div className="row">
              <div className="col-12">
                <p className="error-msg">{error}</p>
              </div>
              <Button className="c-join-shea__submit" type="submit" variant="nonary" form="br-50" disabled={isLoading}>
                Submit
                {isLoading && <LoaderIcon width={13} height={13} className="c-join-shea__loaderIcon" />}
              </Button>
            </div>
          </form>
        ) : (
          <div className="container text-center">
            <p className="h1 text-secondary">Thank you for signing up for our newsletter!</p>
          </div>
        )}
      </section>
    </section>
  );
};

export default JoinSheaForm;
