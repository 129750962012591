import React, { FunctionComponent } from "react";
import { SurveyQuestions } from "../../types/SanityTypes";
import JoinSheaForm from "../JoinSheaForm";

import "./styles.scss";

interface SurveyBlockInterface {
  questions: SurveyQuestions[];
}

const SurveyBlock: FunctionComponent<SurveyBlockInterface> = ({ questions }) => {
  const questionsFormFields = questions.map(({ questionType, question, questionId, choices }) => {
    if (questionType === "text") {
      return {
        type: questionType,
        name: "cq-" + questionId,
        label: question,
        layout: {
          size: 12
        }
      };
    } else if (questionType === "radio") {
      return {
        type: "radioGroup",
        name: "cq-" + questionId,
        label: question,
        layout: {
          size: 12
        },
        options: choices.map(choice => ({
          label: choice.choice,
          value: choice.surveyAnswerId
        }))
      };
    }
  });

  const getQuestionById = id => {
    return questions.find(question => question.questionId === id);
  };

  return (
    <div className="survey-block">
      <JoinSheaForm questions={questionsFormFields} getQuestionById={getQuestionById} />
    </div>
  );
};

export default SurveyBlock;
